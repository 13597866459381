const default_roles = ["Admin", "Editor", "Viewer"]

export const bmm_roles = default_roles
export const tenant_roles = default_roles
export const tenant_customer_roles = default_roles

/**
 * Fetch the user role from the user object
 * @param {object} user user object
 */
export function getUserRole(user) {
    const roles = userRoles(user)
    return roles[0]
}

/**
 * Determine whether user is admin
 * @param {object} user 
 * @returns Boolean 
 */
export function isAdmin(user) { return hasRole(user, "Admin") }

/**
 * Determine whether user is editor
 * @param {object} user 
 * @returns Boolean 
 */
export function isEditor(user) { return hasRole(user, "Editor") }

/**
* Determine whether user is viewer
* @param {object} user 
* @returns Boolean 
*/
export function isViewer(user) { return hasRole(user, "Viewer") }

/**
 * Get all user roles
 * @param {object} user 
 * @returns {Array} user roles
 */
function userRoles(user) {
    const custom_claims = user.role

    const roles = Object.keys(custom_claims)
        .filter(key => default_roles.includes(key))
        .filter(key => custom_claims[key])

    return roles
}

/**
 * Check whether user has certain role
 * @param {Object} user 
 * @param {String} role 
 * @returns {Boolean}
 */
function hasRole(user, role) {
    const roles = userRoles(user)
    return roles.includes(role)
}